


import SECTION_VIPBANNERSECTION from
  '../dynamic_section/dynamic_2/template_01';





import SECTION_VIPCONTENTSECTION from
  '../dynamic_section/dynamic_2/template_01';





import SECTION_VIPCONTENTMSECTION from
  '../dynamic_section/dynamic_2/template_01';






import VIPSection from '../sections/vip/default';






import SECTION_FOOTER_BANNER from
  '../dynamic_section/dynamic_2/template_01';



import "./style.css";


const VipPage = () => {

  return (
    <div id="vipPage" className="page ">




      < SECTION_VIPBANNERSECTION desktopToggle={true}
        mobileToggle={false} label={`vip banner`} id={`vip_banner`} supportLanguage={
          true} titleToggle={false} titleIconToggle={
            true} linkTo={``} addContainer={false} />

      < SECTION_VIPBANNERSECTION desktopToggle={false}
        mobileToggle={false} label={`vip banner`} id={`vip_banner_m`} supportLanguage={
          true} titleToggle={false} titleIconToggle={
            true} linkTo={``} addContainer={false} />











      <VIPSection desktopToggle={true} mobileToggle={
        true} bgSection={false} mobileTitleToggle={true} mobileBannerToggle={true} desktopBannerToggle={
          false} mode={`scroll`} vipContentToggle={true} joinNowContentToggle={false} aboutUsToggle={true}
        faqToggle={false} tncToggle={true} benefitToggle={
          true} reviewToggle={false} requirementToggle={true} vipOrder={["ABOUTUS", "BENEFIT","REQUIREMENT", "TNC"]} linkedSlider={[ { tabCode: "BENEFIT", numberOfImage: 5 }]}/>






      {/* < SECTION_FOOTER_BANNER desktopToggle={ true }
    mobileToggle={ false } label={ `Dynamic Section2` } id={ `footer_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } /> */}





    </div>
  )
}

export default VipPage;